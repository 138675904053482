import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

import shoppingCart from 'shared/images/shopping-cart.png'
import { Collapsible } from 'shared/components/Collapsible'
import { Colors } from 'shared/styles/Colors'
import { getDay, getISODateOnly } from 'shared/utils/dates'
import { Header } from 'web/pages/OrderRequest/styles'
import { isEmptyShallow } from 'shared/utils/isEmpty'
import { Offering } from './Offering'
import { OrderRequestLayout } from 'web/pages/OrderRequest/OrderRequestLayout'
import { PlatformStorage } from 'shared/services/PlatformStorage'
import { PresaleItem } from './PresaleItem'
import { StorageKeys } from 'shared/constants/constants'
import { useOfferingsByCategory } from 'shared/hooks/useOfferingsByCategories'
import { useOrderContext } from 'web/contexts/OrderContext'
import { useUpdateOrder } from 'shared/hooks/useUpdateOrder'

const processSelected = (orderItems) => (acc, itm) => {
  if (orderItems.some((oi) => oi.offeringId === itm.id)) {
    return { items: [...acc.items, itm], ids: new Set([...acc.ids, itm.id]) }
  }

  return acc
}

export const BakedGoods = ({ bakerySlug }) => {
  const { state } = useLocation()
  const { bakery, order } = useOrderContext()
  const { loading, updateOrder } = useUpdateOrder()

  useEffect(() => {
    //  Pre-load the image, so it won't pop-up when Customer adds something to the cart.
    new Image().src = shoppingCart
  }, [])

  const defaultOrderItems = useMemo(
    () =>
      order.orderItems.map((item) => ({
        offeringId: item.offering.id,
        quantity: item.quantity,
        unit: item.unit,
        spec: item.spec,
      })),
    [order],
  )

  const stateItems = useMemo(() => {
    const selectedItems = [
      ...bakery.selectedPresaleItems.filter((item) => item.id === state?.itemId),
      ...bakery.selectedMenuItems.filter((item) => item.id === state?.itemId),
    ]

    return selectedItems.map((item) => ({
      offeringId: item.id,
      quantity: 0,
    }))
  }, [state, bakery])

  const [orderItems, setOrderItems] = useState([...defaultOrderItems, ...stateItems])

  const [dueDay, dueDate] = useMemo(() => {
    const presetCompletionDate =
      order.completionDate || PlatformStorage.getItem(StorageKeys.completionDate)

    if (!presetCompletionDate) return [null, null]

    const jsDate = new Date(presetCompletionDate + 'T00:00:00')

    return [getDay(jsDate), getISODateOnly(jsDate)]
  }, [order])

  const allItems = useMemo(() => {
    const items = [...bakery.selectedPresaleItems, ...bakery.selectedMenuItems]
    const preselectedItems = items.filter((item) => item.id === state?.itemId)
    const preselectedPrefs = preselectedItems.reduce(
      (acc, pi) => {
        const { preference, type } = pi?.availabilityPreferences || {}
        if (isEmptyShallow(preference)) return acc
        else return { ...acc, [type]: [...new Set([...acc[type], ...preference])] }
      },
      { recurring: [], date: [] },
    )

    if (
      dueDate ||
      dueDay ||
      !isEmptyShallow([...preselectedPrefs.recurring, ...preselectedPrefs.date])
    ) {
      return items.filter((item) => {
        const { preference: curItmPref, type: curItmType } = item.availabilityPreferences

        switch (true) {
          case !curItmPref?.length: // Product without availability preferences
            return true

          case !!(dueDate || dueDay): // Preset date as when Customer goes through the Availability modal
            return curItmPref.includes(dueDay) || curItmPref.includes(dueDate)

          // if above is false that means that we now should check the preselected items' preferences

          default:
            return preselectedPrefs[curItmType].some((pp) => curItmPref.includes(pp))
        }
      })
    } else return items
  }, [bakery, dueDate, dueDay, state])

  const onNext = ({ navigateToNextScreen }) => {
    updateOrder({ input: { orderItems } }, navigateToNextScreen)
  }

  const toggleOrderItem = useCallback((offeringId) => {
    setOrderItems((prevOrderItems) => {
      const exists = prevOrderItems.some((oi) => oi.offeringId === offeringId)

      if (exists) {
        return prevOrderItems.filter((item) => item.offeringId !== offeringId)
      } else {
        return [...prevOrderItems, { offeringId, quantity: 0 }]
      }
    })
  }, [])

  const selectedItems = useMemo(
    () => allItems.reduce(processSelected(orderItems), { items: [], ids: new Set() }),
    [orderItems, allItems],
  )

  const itemsByCategory = useOfferingsByCategory(allItems)

  const selectedBakedGoods = useMemo(
    () =>
      bakery.selectedBakedGoods.reduce(processSelected(orderItems), { items: [], ids: new Set() }),
    [orderItems, bakery.selectedBakedGoods],
  )

  const cartNotEmpty = !!(selectedItems.items.length || selectedBakedGoods.items.length)
  const moreThanOneAvailable = !!(allItems.length > 1 || bakery.selectedBakedGoods.length > 1)

  return (
    <OrderRequestLayout
      nextDisabled={!orderItems.length || loading}
      onNext={onNext}
      bakerySlug={bakerySlug}
    >
      <Header>
        {moreThanOneAvailable && cartNotEmpty ? (
          <>
            Your Cart
            <img src={shoppingCart} />
          </>
        ) : (
          'What would you like to order?'
        )}
      </Header>

      <OffersContainer>
        {allItems.length > 1 &&
          selectedItems.items.map((offering) => (
            <PresaleItem
              currencyId={bakery.currency.id}
              isInstantCheckout={!!bakery.instantCheckoutMethod}
              key={offering.slug}
              offeringSlug={offering.slug}
              onClick={toggleOrderItem}
              selected
              userId={bakery.user.id}
            />
          ))}

        {bakery.selectedBakedGoods.length > 1 &&
          selectedBakedGoods.items.map((offering) => (
            <Offering
              selected
              key={offering.id}
              offeringId={offering.id}
              onClick={toggleOrderItem}
            />
          ))}

        {moreThanOneAvailable && cartNotEmpty ? <Header>Add to your order</Header> : ''}

        {Object.keys(itemsByCategory)
          .sort()
          .map((categoryKey, categoryIdx) => (
            <Collapsible
              key={categoryKey}
              expanded
              Header={itemsByCategory[categoryKey][0].category?.name || 'Uncategorized'}
              headerStyle={CollapsibleHeaderStyle}
              containerStyle={{
                padding: '15px 0 0',
                marginBottom: categoryIdx + 1 === Object.keys(itemsByCategory).length ? 20 : 0,
              }}
            >
              {itemsByCategory[categoryKey].map((offering, offeringIdx) => (
                <PresaleItem
                  currencyId={bakery.currency.id}
                  isInstantCheckout={!!bakery.instantCheckoutMethod}
                  key={offering.id}
                  marginTop={offeringIdx === 0 ? 20 : undefined}
                  offeringSlug={offering.slug}
                  onClick={toggleOrderItem}
                  userId={bakery.user.id}
                  selected={selectedItems.ids.has(offering.id)}
                />
              ))}
            </Collapsible>
          ))}

        {!allItems.length &&
          bakery.selectedBakedGoods.map((offering) => (
            <Offering
              selected={selectedBakedGoods.ids.has(offering.id)}
              key={offering.id}
              offeringId={offering.id}
              onClick={toggleOrderItem}
            />
          ))}
      </OffersContainer>
    </OrderRequestLayout>
  )
}

const OffersContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  flexDirection: 'column',
})

const CollapsibleHeaderStyle = {
  marginBottom: 0,
  fontSize: '1.8rem',
  paddingBottom: 14,
  borderBottomWidth: 2,
  borderColor: Colors.grey25,
  borderBottomStyle: 'solid',
}
