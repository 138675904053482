import React, { createContext, use, useCallback, useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { OrderInvoiceDetailsDocument } from 'web/graphql/queries/Order'
import { UpdateInvoiceDocument } from 'web/graphql/mutations'
import { useOrderContext } from 'web/contexts/OrderContext'
import { usePayment } from 'web/hooks/usePayment'

const InvoiceContext = createContext()

export const InvoiceProvider = ({ children }) => {
  const { orderNumber } = useParams()
  const existingOrder = useOrderContext()?.order
  const [updateInvoiceMutation] = useMutation(UpdateInvoiceDocument, {
    cachePolicy: 'cache-first',
  })

  const { data, loading, error, refetch } = useQuery(OrderInvoiceDetailsDocument, {
    variables: { orderNumber },
    skip: existingOrder,
    fetchPolicy: 'cache-first',
  })

  const order = useMemo(() => existingOrder || data?.order, [data, existingOrder])

  const isPendingConfirmation = useMemo(
    () =>
      (order?.depositInvoice?.totalDueInCents === 0 && order?.state === 'pendingDeposit') ||
      (order?.state === 'instantCheckoutRequested' && order?.allowOtherPayments),
    [order],
  )

  const { payInvoice, payStripe, paymentError, paymentLoading, stripeLoading } = usePayment({
    order,
    refetch,
  })

  const viewInvoice = useCallback(
    async (id) => {
      await updateInvoiceMutation({
        variables: {
          input: {
            id,
            viewed: true,
          },
        },
        skip: existingOrder,
      })
    },
    [existingOrder, updateInvoiceMutation],
  )

  const value = {
    payInvoice,
    payStripe,
    viewInvoice,
    paymentError,
    paymentLoading,
    stripeLoading,
    order,
    error,
    loading,
    refetch,
    isPendingConfirmation,
  }

  return <InvoiceContext value={value}>{children}</InvoiceContext>
}

export const useInvoiceContext = () => use(InvoiceContext)
export const InvoiceConsumer = InvoiceContext.Consumer
