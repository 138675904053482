import 'react-dropdown/style.css'
import Dropdown from 'react-dropdown'
import React, { memo } from 'react'
import styled from 'styled-components'

import { Colors } from 'shared/styles/Colors'
import { MediaQueries } from 'web/styles/responsive'

export const MobileNumberFieldDropdown = memo(({ countryCodes, setPhoneCode, phoneCode }) => (
  <MobileNumberCountryContainer>
    <Dropdown options={countryCodes} value={phoneCode} onChange={setPhoneCode} />
  </MobileNumberCountryContainer>
))

const MobileNumberCountryContainer = styled.div`
  border: 1px solid ${Colors.grey25};
  border-right: none;
  background-color: ${Colors.white};
  border-radius: 4px 0px 0px 4px;
  -webkit-appearance: none;
  outline: none;
  -webkit-appearance: none;
  width: 40%;

  @media ${MediaQueries.mdUp} {
    width: 25%;
  }

  .Dropdown-root {
    height: 100%;
  }

  .Dropdown-control {
    height: 100%;
    border: none;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  .Dropdown-arrow {
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
`
