import React, { createContext, use, useEffect, useMemo } from 'react'

import { bakerIdVar } from 'web/App'
import { BannerProvider } from 'web/contexts/AnnouncementBannerContext'
import { FetchError } from 'shared/components/FetchError'
import {
  OrderInvoiceDetailsDocument,
  OrderLayoutDocument,
  OrderRequestDocument,
  OrderReviewDocument,
} from 'web/graphql/queries/Order'
import { Spinner } from 'shared/components/Spinner'
import { ThemeProvider } from 'web/contexts/ThemeContext'
import { useBakesyQuery } from 'shared/hooks/useBakesyQuery'
import { useCurrentRoute } from 'web/hooks/useCurrentPath'

const orderRoutes = [
  { path: '/orders/:orderNumber' },
  { path: '/print-order/:orderNumber' },
  { path: '/order-request/new/:bakeryId' },
  { path: '/order-request/:orderId/*' },
  { path: '/review/:orderNumber' },
  { path: '/contracts/:orderNumber' },
]

const queries = {
  '/orders/:orderNumber': OrderInvoiceDetailsDocument,
  '/print-order/:orderNumber': OrderLayoutDocument,
  '/order-request/:orderId/*': OrderRequestDocument,
  '/review/:orderNumber': OrderReviewDocument,
  '/contracts/:orderNumber': OrderLayoutDocument,
}

const OrderContext = createContext()

export const OrderProvider = ({ children }) => {
  const currentRoute = useCurrentRoute(orderRoutes)
  const query = queries[currentRoute.route.path] || OrderLayoutDocument
  const { orderId, orderNumber } = currentRoute.params
  const skip = !(orderNumber || orderId)

  console.log('CURRENT ROUTE', currentRoute)

  const {
    data: order,
    loading,
    error,
    refetch,
  } = useBakesyQuery(query, {
    fetchPolicy: 'cache-first',
    variables: { id: orderId, orderNumber },
    transform: (data) => data?.order,
    skip,
  })

  const bakery = useMemo(() => order?.bakery ?? {}, [order])

  useEffect(() => {
    bakerIdVar(bakery?.user?.id)
  }, [bakery?.user?.id])

  if (loading) return <Spinner page />
  if (error) return <FetchError page onRetry={refetch} message={error.message} />
  if (skip) return children

  return (
    <OrderContext
      value={{
        bakery,
        error,
        loading,
        order,
        refetch,
      }}
    >
      <ThemeProvider
        buttonPreferencesId={bakery.buttonPreferences?.id}
        premiumThemeId={bakery.premiumTheme?.id}
        storefrontAppearanceId={bakery.storefrontAppearance?.id}
      >
        <BannerProvider
          bannerId={bakery.announcementBanner?.id}
          enableAnnouncementBanner={bakery.enableAnnouncementBanner}
          themeId={bakery.premiumTheme?.id}
        >
          {children}
        </BannerProvider>
      </ThemeProvider>
    </OrderContext>
  )
}

export const useOrderContext = () => use(OrderContext)
