import React, { useEffect } from 'react'
import styled from 'styled-components'

import {
  BakerySection,
  CommentSection,
  ContractSection,
  Head,
  ImageSection,
  InvoiceNoteSection,
  ItemSection,
  NoteSection,
  PaySection,
  QuestionSection,
  ScheduleSection,
} from 'web/components/Invoice'

import { ActionBar, CostSection, StatusSection, TitleSection } from './'
import { ContractContainer, Divider, PaymentContainer, SectionContainer } from './styles'
import { ErrorMessage } from 'shared/components/Form'
import { FetchError } from 'shared/components/FetchError'
import { FixedWidthContainer } from 'web/styles/responsive'
import { PoweredBy } from '../PoweredBy'
import { Spinner } from 'shared/components/Spinner'
import { useCurrentUser } from 'shared/hooks/useCurrentUser'
import { useInvoiceContext } from 'shared/contexts/InvoiceContext'
import { useResponsiveLayout } from 'web/hooks/useResponsiveLayout'
import { withOwnership } from 'web/components/Invoice/withOwnership'

const Invoice = ({ printOrder = false }) => {
  const currentUser = useCurrentUser()
  const { isMdUp } = useResponsiveLayout()
  const {
    order,
    error,
    loading,
    refetch,
    paymentLoading,
    paymentError,
    isPendingConfirmation,
    viewInvoice,
  } = useInvoiceContext()

  const hasPremium = order?.bakery?.user?.subscriptionTier === 'Premium'

  useEffect(() => {
    if (order) {
      const invoiceId = order.finalInvoice?.id || order.depositInvoice?.id
      if (invoiceId && !order.isInstantCheckout && !printOrder) viewInvoice(invoiceId)
    }
  }, [order, printOrder, viewInvoice])

  useEffect(() => {
    !loading && !error && printOrder && setTimeout(() => window.print(), 200)
  }, [error, loading, printOrder])

  if (loading || paymentLoading) return <Spinner page />
  if (error) return <FetchError page onRetry={refetch} message={error.message} />

  const isFinalized = order.subtotalAmountCents !== null
  const isCollapsed = !isMdUp || !isFinalized || printOrder

  return (
    <Container $isMdUp={isMdUp}>
      <Head />

      {isPendingConfirmation && (
        <>
          <Row $collapsed={isCollapsed}>
            <MainColumn>
              <TitleSection order={order} />
            </MainColumn>

            <SecondaryColumn $printOrder={printOrder}>
              <ActionBar />
            </SecondaryColumn>
          </Row>

          <Divider $marginTop={5} $marginBottom={15} />
        </>
      )}

      <Row $collapsed={isCollapsed}>
        <MainColumn>
          {!isPendingConfirmation && (
            <>
              <TitleSection order={order} />
              <Divider $marginTop={5} $marginBottom={15} />
            </>
          )}

          <ErrorMessage error={paymentError} />

          {!!order.attachment && <ContractSection Container={ContractContainer} />}
          <PaySection show={!printOrder} Container={PaymentContainer} currentUser={currentUser} />
          <InvoiceNoteSection show Container={SectionContainer} />
          <CostSection show={isFinalized} Container={SectionContainer} />
          {!order.isInstantCheckout && (
            <ScheduleSection
              show={isFinalized}
              Container={SectionContainer}
              printOrder={printOrder}
            />
          )}
        </MainColumn>

        <SecondaryColumn>
          <NoteSection show={!!order.bakerOrderDetails} Container={SectionContainer} />

          <SectionContainer>
            <BakerySection show />
            <StatusSection printOrder={printOrder} />
            <ItemSection printOrder={printOrder} show />
            <ImageSection show />
            <QuestionSection show />
            <CommentSection show />
          </SectionContainer>
        </SecondaryColumn>
      </Row>
      {!hasPremium && <PoweredBy />}
    </Container>
  )
}

export default withOwnership(Invoice)

const Container = styled(FixedWidthContainer)(({ $isMdUp }) => ({
  marginTop: $isMdUp ? 40 : 20,
}))

const Row = styled.div(({ $collapsed }) => ({
  display: 'flex',
  flexDirection: $collapsed ? 'column' : 'row',
}))

const MainColumn = styled.div({
  flex: '60%',
  padding: '0 5px',
})

const SecondaryColumn = styled.div(({ $printOrder }) => ({
  display: $printOrder ? 'none' : 'block',
  flex: '40%',
  padding: '0 5px',
}))
