import React, { createContext, use, useEffect } from 'react'

import { bakerIdVar } from 'web/App'
import {
  BakeryDetailsDocument,
  BakeryGalleryImagesDocument,
  BakeryLayoutDocument,
  BakeryOfferingDocument,
  BakeryOfferingsDocument,
  BakeryReviewsDocument,
} from 'web/graphql/queries/Bakery'
import { BakeryPageLayout } from 'web/components/BakeryPageLayout'
import { BannerProvider } from 'web/contexts/AnnouncementBannerContext'
import { FetchError } from 'shared/components/FetchError'
import { Spinner } from 'shared/components/Spinner'
import { ThemeProvider } from 'web/contexts/ThemeContext'
import { useBakesyQuery } from 'shared/hooks/useBakesyQuery'
import { useCurrentRoute } from 'web/hooks/useCurrentPath'

const bakeryRoutes = [
  { path: '/b/:slug' },
  { path: '/b/:slug/category/:categorySlug' },
  { path: '/b/:slug/faqs' },
  { path: '/b/:slug/gallery' },
  { path: '/b/:slug/getreviews' },
  { path: '/b/:slug/products/:productSlug' },
  { path: '/b/:slug/reviews' },
]

const queries = {
  '/b/:slug': BakeryDetailsDocument,
  '/b/:slug/category/:categorySlug': BakeryOfferingsDocument,
  '/b/:slug/faqs': BakeryLayoutDocument,
  '/b/:slug/gallery': BakeryGalleryImagesDocument,
  '/b/:slug/getreviews': BakeryReviewsDocument,
  '/b/:slug/products/:productSlug': BakeryOfferingDocument,
  '/b/:slug/reviews': BakeryReviewsDocument,
}

export const BakeryContext = createContext()

export const BakeryProvider = ({ children }) => {
  const currentRoute = useCurrentRoute(bakeryRoutes)
  const { slug, productSlug } = currentRoute.params
  const query = queries[currentRoute.route.path] || BakeryDetailsDocument
  const skip = !(slug || productSlug)

  const {
    data: bakery,
    loading,
    error,
    refetch,
  } = useBakesyQuery(query, {
    fetchPolicy: 'cache-first',
    transform: (data) => data?.bakery,
    skip,
    variables: {
      slug,
      bakerySlug: slug,
      productSlug,
      visit: true,
    },
  })

  useEffect(() => {
    bakerIdVar(bakery?.user?.id)
  }, [bakery?.user?.id])

  if (loading) return <Spinner page />
  if (error) return <FetchError page onRetry={refetch} message={error.message} />
  if (skip) return children

  return (
    <BakeryContext value={{ bakery, refetch }}>
      <ThemeProvider
        buttonPreferencesId={bakery?.buttonPreferences?.id}
        premiumThemeId={bakery?.premiumTheme?.id}
        storefrontAppearanceId={bakery?.storefrontAppearance?.id}
      >
        <BannerProvider
          bannerId={bakery?.announcementBanner?.id}
          enableAnnouncementBanner={bakery?.enableAnnouncementBanner}
        >
          <BakeryPageLayout>{children}</BakeryPageLayout>
        </BannerProvider>
      </ThemeProvider>
    </BakeryContext>
  )
}

export const useBakeryContext = () => use(BakeryContext)

export default BakeryContext
