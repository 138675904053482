import React from 'react'
import { useNavigate } from 'react-router-dom'

import { BakeryPageLayout } from 'web/components/BakeryPageLayout'
import { FetchError } from 'shared/components/FetchError'
import { ReviewComponent } from './ReviewComponent'
import { useOrderContext } from 'web/contexts/OrderContext'

export const OrderReview = () => {
  const navigate = useNavigate()
  const { order, bakery, refetch } = useOrderContext()

  if (!bakery) return <FetchError page onRetry={refetch} />
  if (order.state !== 'complete') return navigate(`/b/${order.bakery.slug}`)

  return (
    <BakeryPageLayout bakeryProp={bakery}>
      <ReviewComponent bakery={bakery} refetch={refetch} />
    </BakeryPageLayout>
  )
}
