import React, { useEffect } from 'react'
import styled from 'styled-components/native'
import { gql } from '@apollo/client'
import { MaskedTextInput } from 'react-native-mask-text'

import * as Cross from 'shared/components/Cross'
import { Colors } from 'shared/styles/Colors'
import { Configuration } from 'shared/services/Configuration'
import { ErrorMessage } from 'shared/components/Form'
import { Fonts } from 'shared/styles/Typography'
import { insertIfElse } from 'shared/utils/insertIfFunctions'
import { MobileNumberFieldDropdown } from './MobileNumberFieldDropdown'
import { setCountryMask, setCountryPlaceholder } from 'shared/utils/phone'
import { useBakesyQuery } from 'shared/hooks/useBakesyQuery'

export const MobileNumberField = React.memo(
  ({
    ref,
    autoFocus = false,
    error,
    mobileNumber,
    setMobileNumber,
    phoneCode,
    setPhoneCode,
    label = null,
    Container = null,
    onSubmit = null,
    inputStyle = {},
    pickerStyle = {},
  }) => {
    const ContainerComponent = Container || Cross.View
    const allowFontScaling = Object.keys(inputStyle).length > 0

    const { data: countryCodes } = useBakesyQuery(COUNTRY_PHONE_CODES, {
      transform: (data) => {
        return data?.countryPhoneCodes?.map((i) => ({
          ...insertIfElse(Configuration.isWeb, { value: i.countryCode }, i),
          label: `${i.code} ${i.countryCode} ${i.flag}`,
        }))
      },
      initialValue: [],
    })

    useEffect(() => {
      if (countryCodes.length) {
        setPhoneCode(countryCodes.filter((i) => i.countryCode === phoneCode?.countryCode)[0])
      }
    }, [countryCodes, phoneCode?.countryCode, setPhoneCode])

    console.log('FIELD PHONE CODE', phoneCode)
    console.log('FIELD COUNTRY CODES', countryCodes)

    return (
      <ContainerComponent>
        {label && (
          <LabelContainer>
            <Label allowFontScaling={allowFontScaling}>{label}</Label>
          </LabelContainer>
        )}
        <DropDownContainer>
          <MobileNumberContainer>
            <MobileNumberFieldDropdown
              countryCodes={countryCodes}
              phoneCode={phoneCode}
              pickerStyle={pickerStyle}
              setPhoneCode={setPhoneCode}
            />

            <MobileNumberFieldElement
              allowFontScaling={allowFontScaling}
              autoFocus={autoFocus}
              type="custom"
              mask={setCountryMask(phoneCode?.countryCode)}
              value={mobileNumber}
              onChangeText={setMobileNumber}
              placeholder={setCountryPlaceholder(phoneCode?.countryCode)}
              placeholderTextColor="rgb(150, 159, 167)"
              autoCompleteType="tel"
              keyboardType="number-pad"
              textContentType="telephoneNumber"
              onSubmitEditing={onSubmit}
              style={inputStyle}
              ref={ref}
            />
          </MobileNumberContainer>
        </DropDownContainer>

        <ErrorMessage error={error} />
      </ContainerComponent>
    )
  },
)

const LabelContainer = styled.View({
  marginBottom: 10,
})

const Label = styled(Cross.Text)({
  fontSize: 18,
})

const MobileNumberFieldElement = styled(MaskedTextInput)({
  paddingHorizontal: 10,
  paddingVertical: 10,
  fontSize: Configuration.isWeb ? 18 : 30,
  fontFamily: Configuration.isWeb ? 'inherit' : Fonts.SourceSansPro.Regular,
  color: Colors.black,
  borderColor: Colors.grey25,
  borderWidth: 1,
  backgroundColor: Colors.white,
  borderTopRightRadius: 4,
  borderBottomRightRadius: 4,
  flex: 3,
})

const MobileNumberContainer = styled.View({
  flexDirection: 'row',
})

const DropDownContainer = styled.View({})

const COUNTRY_PHONE_CODES = gql`
  query CountryPhoneCodes {
    countryPhoneCodes {
      code
      name
      flag
      countryCode
    }
  }
`
